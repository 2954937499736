
<template>
  <div class="mvp-avatar">
    <span class="inline-flex items-center justify-center rounded-full bg-gray-500 overflow-hidden"
      :class="`h-${size} w-${size}`"
    >
      <img v-if="user.avatar"
        class="inline-block rounded-full"
        :class="`h-${size} w-${size}`"
        :src="storageUrl + '/avatars/' + user.avatar + '.png'"
        alt="" />
      <span v-if="!user.avatar">
        <span v-if="user.firstname && user.lastname"
          class="font-medium leading-none text-white"
          :class="parseInt(size, 10) > 6 ? 'text-base' : 'text-xs'"
        >
          {{ firstLetter(user.firstname) + firstLetter(user.lastname) }}
        </span>
        <span v-else class="rounded-full"
          :class="`h-${size} w-${size}`">
          <svg class="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
            <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
          </svg>
        </span>
      </span>
    </span>
  </div>
</template>

<script>
export default {
  name: 'Avatar',
  props: {
    user: {
      type: Object
    },
    size: {
      default: '10',
      type: String
    }
  },
  data () {
    return {
      storageUrl: process.env.VUE_APP_STORAGE_URL
    }
  },
  methods: {
    firstLetter (word) {
      if (!word) {
        return ''
      }
      return word.charAt(0).toUpperCase()
    }
  }
}
</script>
