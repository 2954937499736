<template>
  <div>
    <PageHeader
      title="Modification d'un utilisateur"
    />
    <PageContent v-if="user">
      <div class="m-auto max-w-6xl shadow sm:rounded-md">
        <div class="space-y-6 bg-white sm:p-6">
          <div class="grid grid-cols-4 gap-6">
              <t-input-group :class="`col-span-${ hasRoleSelect? '2' : '4' }`" label="Avatar">
                <b-upload @input="uploadAvatar" accept="image/png, image/jpeg">
                  <avatar :user="user"/>
                </b-upload>
              </t-input-group>
              <t-input-group class="col-span-2" label="Rôle" v-if="hasRoleSelect">
                <t-select
                  placeholder="Select a name"
                  v-model="user.accreditations[0]"
                  :options="translatedRoles"
                  @input="updateRole"
                />
              </t-input-group>
              <t-input-group class="col-span-2" label="Nom">
                <t-input v-model="user.lastname" placeholder="Nom">
                </t-input>
              </t-input-group>
              <t-input-group class="col-span-2" label="Prénom">
                <t-input v-model="user.firstname" placeholder="Prénom">
                </t-input>
              </t-input-group>
              <t-input-group class="col-span-2" label="Adresse e-mail">
                <t-input type="email" v-model="user.email" required placeholder="Adresse e-mail" disabled>
                </t-input>
              </t-input-group>
              <t-input-group class="col-span-2" label="Organisation">
                <t-input v-model="user.organization" required placeholder="Organisation">
                </t-input>
              </t-input-group>
              <t-input-group class="col-span-2" label="Peut répondre aux conversations" v-if="user.accreditations.includes('director')">
                <t-toggle v-model="user.canUseChat"/>
              </t-input-group>

          </div>
        </div>
        <div class="bg-gray-50 px-4 py-3 flex gap-3 justify-end">
          <div class="flex gap-3">
            <t-button class="m-0" variant="error" @click.prevent="deleteUser">Supprimer</t-button>
            <t-button @click.prevent="updateUser">Mettre à jour</t-button>
          </div>
        </div>
      </div>
    </PageContent>
  </div>
</template>

<script>
import { http } from '@/services/api.service'
import { mapGetters } from 'vuex'
import Avatar from '@/components/Avatar'
import PageHeader from '@/components/PageHeader.vue'
import PageContent from '@/components/PageContent.vue'

export default {
  name: 'app-question',
  components: { PageHeader, PageContent, Avatar },
  data () {
    return {
      user: null,
      storageUrl: process.env.VUE_APP_STORAGE_URL,
      basicAvatar: '/logo.png',
      allRoles: ['user', 'operator', 'director'],
      rolesTraduction: {
        user: 'Utilisateur',
        'community-manager': 'Community Manager',
        operator: 'Observateur',
        manager: 'Opérateur',
        director: 'Admin'
      },
      hasRoleSelect: false
    }
  },
  computed: {
    ...mapGetters([
      'roles'
    ]),
    translatedRoles () {
      const filteredRoles = {}
      for (let role of this.allRoles) {
        filteredRoles[role] = this.rolesTraduction[role]
      }
      return filteredRoles
    }
  },
  mounted () {
    if (['admin', 'community-manager'].some(role => this.roles.includes(role))) {
      this.allRoles.push('director')
    }
    this.getUser()
  },
  methods: {
    setIfHasRoleSelect () {
      const isAdmin = ['admin', 'community-manager'].some(role => this.roles.includes(role))
      if ((this.user.accreditations[0] !== 'director' && !isAdmin) || isAdmin) {
        this.hasRoleSelect = true
      }
    },
    updateRole (value) {
      http.put(`/user/${this.$route.params.uuid}/role`, { role: value }).then(({ data }) => {
        this.$buefy.notification.open({
          duration: 3000,
          message: 'Le role de cet utilisateur à bien été mis à jour !',
          position: 'is-bottom-left',
          type: 'is-success',
          hasIcon: true
        })
      }).catch(err => {
        console.log(err)
        this.$buefy.notification.open({
          duration: 3000,
          message: 'Nos serveurs rencontrent des difficultés à mettre à jour le role de cet utilisateur',
          position: 'is-bottom-left',
          type: 'is-warning',
          hasIcon: true,
          icon: 'server'
        })
      })
    },
    getUser () {
      http.get(`/user/${this.$route.params.uuid}`).then(({ data }) => {
        this.user = data
        this.user.canUseChat = this.user.can_use_chat
        this.setIfHasRoleSelect()
      }).catch(err => {
        console.log(err)
        this.$buefy.notification.open({
          duration: 3000,
          message: 'Nos serveurs rencontrent des difficultés à récupérer les informations concernant cet utilisateur',
          position: 'is-bottom-left',
          type: 'is-warning',
          hasIcon: true,
          icon: 'server'
        })
      })
    },
    updateUser () {
      const { firstname, lastname, organization, canUseChat } = this.user
      http.put(`/user/${this.user.uuid}`, { firstname, lastname, organization, can_use_chat: canUseChat }).then(res => {
        this.$buefy.notification.open({
          duration: 3000,
          message: 'L\'utilisateur a été mis à jour avec succès !',
          position: 'is-bottom-left',
          type: 'is-success',
          hasIcon: true
        })
        this.$router.push({ path: `/app/users/${this.user.accreditations[0]}` })
      }).catch(err => {
        console.log(err)
        this.$buefy.notification.open({
          duration: 3500,
          message: 'Nos serveurs rencontrent des difficultés à mettre à jour cet utilisateur',
          position: 'is-bottom-left',
          type: 'is-warning',
          hasIcon: true,
          icon: 'server'
        })
      })
    },
    deleteUser () {
      this.$buefy.dialog.confirm({
        title: 'Suppression',
        message: `Êtes vous sur de vouloir supprimer cet utilisateur ?`,
        cancelText: 'Annuler',
        confirmText: 'Confirmer',
        type: 'is-danger',
        onConfirm: () => {
          http.delete(`/user/${this.user.uuid}`).then(res => {
            this.$buefy.notification.open({
              duration: 3000,
              message: 'L\'utilisateur a été supprimé avec succès !',
              position: 'is-bottom-left',
              type: 'is-success',
              hasIcon: true
            })
            this.$router.push({ path: `/app/users/${this.$route.query.role}` })
          }).catch(err => {
            console.log(err)
            this.$buefy.notification.open({
              duration: 3000,
              message: 'Nos serveurs rencontrent des difficultés à supprimer cet utilisateur',
              position: 'is-bottom-left',
              type: 'is-warning',
              hasIcon: true,
              icon: 'server'
            })
          })
        }
      })
    },
    uploadAvatar (file) {
      const formData = new FormData()
      formData.append('avatar', file)
      http.post(`/user/${this.user.uuid}/avatar`, formData).then(({ data }) => {
        this.user = data
        // this.domain.logo = data.logo
        this.$buefy.notification.open({
          duration: 3000,
          message: 'Avatar mis à jour',
          position: 'is-bottom-left',
          type: 'is-success',
          hasIcon: true
        })
      }).catch(err => {
        console.log(err)
        this.$buefy.notification.open({
          duration: 3500,
          message: 'Nos serveurs rencontrent des difficultés à mettre à jour le logo du domaine',
          position: 'is-bottom-left',
          type: 'is-warning',
          hasIcon: true,
          icon: 'server'
        })
      })
    }
  }
}
</script>
